import { Fragment } from "react";
import Header from "../components/Header/Header";
import About from "../components/About/About";
import Facilities from "../components/Facilities/Facilities";
import WorkHours from "../components/WorkHours/WorkHours";
import SavedHearts from "../components/SavedHearts/SavedHearts";
import Team from "../components/Team/Team";
import Testimonials from "../components/Testimonials/Testimonials";
import Contact from "../components/Contact/Contact";

const Home = () => {
  return (
    <Fragment>
      <Header />
      <About />
      <Facilities />
      <WorkHours />
      <SavedHearts />
      <Team />
      <Testimonials />
      <Contact />
    </Fragment>
  );
};

export default Home;
