import { Fragment } from "react";

import doctor_img from "../../assets/headerDoc.jpg";
import Ticker from "../../UI/Ticker";

const Header = () => {
  return (
    <div className="bg-[url('../src/assets/headerBg.png')] md:bg-none bg-cover bg-no-repeat bg-opacity-[20%]">
      <div className="container mx-auto py-12" id="home" > 
        <div className="flex flex-col-reverse md:flex-row items-center lg:pt-12 pt-10 md:justify-between ">
          <div className="flex flex-col items-center md:items-start gap-5 md:gap-5 lg:gap-10 py-20 text-center md:text-start">
            <h1 className="md:max-w-[400px] lg:max-w-[500px] lg:text-[50px] xl:text-[60px] md:text-[40px] text-[70px]">
              Prime Care Walk-In Clinic
            </h1>
            <p className="body-1 max-w-[342px] lg:max-w-[500px]  lg:leading-normal md:text-[16px]">
            Your Wellness, Our Commitment! Discover a healthcare experience built on trust, compassion, and expertise. Your well-being is not just a priority; it's our unwavering commitment.
            </p>
            <div className="mt-8">
              <a
                href="#contact"
                className="text-white bg-neonCyan px-[40px] py-5 rounded-[60px] text-xl hover:bg-black transition-[0.25s]"
              >
                Get appointment
              </a>
            </div>
          </div>
          <div>
            <img
              src={doctor_img}
              className="md:max-w-[380px] hidden md:block rounded-[40px] lg:max-w-[450px] xl:max-w-[580px]"
              alt=""
            />
          </div>
        </div>
      </div>
      <Ticker />
    </div>
  );
};

export default Header;
