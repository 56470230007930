import { Fragment } from "react";

import logo from "../assets/logo.svg";

const Footer = () => {
  return (
    <Fragment>
      <div className="container mx-auto flex flex-col md:flex-row justify-center md:justify-start lg:gap-20 md:gap-10 px-14 py-8">
        <div className="flex flex-col gap-10 mb-5">
          <div className="flex justify-center md:justify-start gap-6">
            <a href="#home">
              <img src={logo} alt="" className="md:w-[200px] lg:w-[250px]"/>
            </a>
          </div>
          <p className="lg:text-[18px] md:text-[16px] text-center md:text-start text-[#403634] lg:max-w-[350px] ">
          Your Wellness, Our Commitment!
          </p>
        </div>
        <div className="flex flex-row justify-center gap-10 mb-5">
          <div className="flex flex-col text-center gap-2 lg:gap-4">
            <h3 className="text-[16px] lg:text-[18px] font-black">Company</h3>
            <a href="#home">Home</a>
            <a href="#services">Services</a>
            <a href="#contact">Contact Us</a>
          </div>
        </div>
        <div className="flex flex-col gap-2 lg:gap-4 items-center md:ms-auto">
          <h3 className="text-[18px] lg:text-[24px] font-black">Call us</h3>
          <p className="text-[16px] lg:text-[18px]">(416) 997 - 5559</p>
          <p className="text-[16px] lg:text-[18px]">(365) 509 - 2982</p>
        </div>
      </div>
      <div className="text-center py-1 bg-[#006C72] text-white text-[14px]">Designed by sanemuix.com | Website by modalityit.com</div>
      
    </Fragment>
  );
};

export default Footer;
