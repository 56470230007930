import { Fragment } from "react";

import phone_icon from "../../assets/phone.svg";
import mail_icon from "../../assets/mail.svg";
import location_icon from "../../assets/location.svg";

import Map from "./Map";
import Ticker from "../../UI/Ticker";

const Contact = () => {
  return (
    <Fragment>
      <div
        className="container mx-auto flex flex-col items-center gap-12 lg:gap-20 py-12 scroll-mt-10"
        id="contact"
      >
        <div className="flex flex-col-reverse md:flex-row md:justify-center md:items-start lg:gap-20 md:gap-5">
          <div className="mt-5">
            <Map />
          </div>
          <div className="flex flex-col items-center md:items-start px-4 lg:px-0 gap-6">
            <span className="text-neonCyan text-base bg-[#F4F6F7] p-2 w-[142px] rounded-md text-center font-bold">
              Contact Us
            </span>
            <h2 className="lg:text-[40px] md:text-[36px]">Get in touch</h2>
            <p className="max-w-[342px] lg:max-w-[580px] text-center md:text-start lg:text-[20px] md:text-[16px]">
            Whether you have questions, or need to schedule an appointment our team is here to assist you. Reach out today and let's start your journey to better health.
            </p>
            <div className="flex flex-row gap-3">
              <img src={phone_icon} className="w-[48px] h-[48px] md:w-[28px] md:h-[28px]" alt="" />
              <div className="flex flex-col justify-center">
                <h3>Call us</h3>
                <p className="text-base w-[210px] lg:w-[384px]">
                  (416) 997 - 5559
                </p>
                <p className="text-base w-[210px] lg:w-[384px]">
                  (365) 509 - 2982
                </p>
              </div>
            </div>
            <div className="flex flex-row gap-3">
              <img src={mail_icon} className="w-[48px] h-[48px] md:w-[28px] md:h-[28px]" alt="" />
              <div className="flex flex-col justify-center">
                <h3>Mail us</h3>
                <p className="text-base w-[210px] lg:w-[384px]">
                  info@primecareclinic.ca
                </p>
              </div>
            </div>
            <div className="flex flex-row gap-3">
              <img src={location_icon} className="w-[48px] h-[48px] md:w-[28px] md:h-[28px]" alt="" />
              <div className="flex flex-col justify-center">
                <h3>Our location</h3>
                <p className="text-base w-[210px] lg:w-[384px]">
                  9160 Bayview Ave, Unit b01, Richmond Hill, ON L4B 0E6
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Ticker />
    </Fragment>
  );
};

export default Contact;
