import { Fragment } from "react";

import quote_icon from "../../assets/quote-icon.svg";
import person_icon_one from "../../assets/person_icon_one.jpg";
import person_icon_two from "../../assets/person_icon_two.jpg";

const Testimonials = () => {
  return (
    <Fragment>
      <div className="container mx-auto flex flex-col items-center gap-10 py-12">
        <span className="text-neonCyan text-[16px] bg-[#F4F6F7] p-2 w-[202px] rounded-md text-center font-bold">
          REVIEWS
        </span>
        <h2 className="lg:max-w-[753px] text-center lg:text-[40px] md:text-[36px] md:pb-5">
          Here’s What Our Customers Have Said
        </h2>
        <div className="flex flex-col items-center md:flex-row gap-10">
          <div className="flex flex-col-reverse lg:flex-col gap-4 bg-[#24C0DD0D] w-[340px] lg:w-[500px] p-2 lg:p-4 lg:h-[406px] md:h-[556px]">
            <img
              src={quote_icon}
              className="w-[50px] hidden lg:block ps-4"
              alt=""
            />
            <p className="px-4  text-start">
            Everyone was attentive and professional. From the admin/ receptionist, to the X-ray and ultrasound technicians. The staff accommodated my needs promptly and followed up before and after my appointment to ensure all was well. Dmitri was amazing! He is an expert in his field,  knowledgeable and caring about your issues. His expertise is the best I’ve ever experienced. Thank you!
            </p>
            <div className="flex flex-col items-center lg:flex-row ps-4 h-full">
              {/* <img src={person_icon_one} className="w-[50px] h-[50px] rounded-full" alt="" /> */}
              <div className="flex flex-col h-full justify-end">
                <h3 className="text-lg">Kathryn Thompson</h3>
                <p className="text-neonCyan">Toronto,ON</p>
              </div>
            </div>
            <img
              src={quote_icon}
              className="w-[60px] h-[60px] lg:hidden"
              alt=""
            />
          </div>
          <div className="flex flex-col-reverse lg:flex-col gap-4 bg-[#24C0DD0D] w-[340px] lg:w-[500px] lg:h-[406px] md:h-[556px] h-[351px] p-2 lg:p-4">
            <img
              src={quote_icon}
              className="w-[50px] hidden lg:block ps-4"
              alt=""
            />
            <p className="px-4 md:text-start mb-auto">
            Very professional staff and excellent care.  Dr Dimitry very professional and extremely knowledgeable and caring.
            </p>
            <div className="flex flex-col items-center lg:flex-row ps-4 h-full">
              {/* <img src={person_icon_two} className="w-[50px] h-[50px] rounded-full" alt="" /> */}
              <div className="flex flex-col h-full justify-end">
                <h3 className="text-lg">Dalia Sabour</h3>
                <p className="text-neonCyan">Toronto,ON</p>
              </div>
            </div>
            <img
              src={quote_icon}
              className="w-[60px] h-[60px] lg:hidden"
              alt=""
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Testimonials;
