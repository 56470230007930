// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hwrap {
  overflow: hidden;
  background: #006C72;
}

.hitem {
  flex-shrink: 0;
  box-sizing: border-box;
  padding: 8px;
  text-align: center;
}

@keyframes tickerh {
  0% {
    transform: translate3d(100%, 0, 0);
  }
  100% {
    transform: translate3d(-200%, 0, 0);
  }
}
.hmove {
  animation: tickerh linear 24s infinite;
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/UI/Ticker.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,sBAAsB;EACtB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE;IACE,kCAAkC;EACpC;EACA;IACE,mCAAmC;EACrC;AACF;AACA;EACE,sCAAsC;AACxC;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".hwrap {\r\n  overflow: hidden;\r\n  background: #006C72;\r\n}\r\n\r\n.hitem {\r\n  flex-shrink: 0;\r\n  box-sizing: border-box;\r\n  padding: 8px;\r\n  text-align: center;\r\n}\r\n\r\n@keyframes tickerh {\r\n  0% {\r\n    transform: translate3d(100%, 0, 0);\r\n  }\r\n  100% {\r\n    transform: translate3d(-200%, 0, 0);\r\n  }\r\n}\r\n.hmove {\r\n  animation: tickerh linear 24s infinite;\r\n}\r\n\r\n.dot {\r\n  width: 12px;\r\n  height: 12px;\r\n  border-radius: 50%;\r\n  background: #fff;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
