import "./Ticker.css";

const Ticker = () => {
  return (
    <div className="overflow-hidden bg-[#006C72]">
      <div className="flex flex-row bg-neonCyan hmove">
        <div className="flex items-center gap-5 hitem">
          <div className="dot"></div>
          <h4 className="text-sm">VASCULAR SURGEON CONSULTATION</h4>
          <div className="dot"></div>
          <h4 className="text-sm">FAMILY DOCTOR SERVICES</h4>
          <div className="dot"></div>
          <h4 className="text-sm">CONSULTATION</h4>
          <div className="dot"></div>
          <h4 className="text-sm">VERICOSE VEINS TREATMENT</h4>
        </div>
        <div className="flex items-center gap-4 hitem">
        <div className="dot"></div>
          <h4 className="text-sm">VASCULAR SURGEON CONSULTATION</h4>
          <div className="dot"></div>
          <h4 className="text-sm">FAMILY DOCTOR SERVICES</h4>
          <div className="dot"></div>
          <h4 className="text-sm">CONSULTATION</h4>
          <div className="dot"></div>
          <h4 className="text-sm">VERICOSE VEINS TREATMENT</h4>
        </div>
      </div>
    </div>
  );
};

export default Ticker;
