import { Fragment, useState } from "react";
import logo from "../assets/logo.svg";
import pdf_icon from "../assets/pdf-icon.svg";
import pdf from "../assets/requisition.pdf"

const navLinks = [
  { name: "HOME", id: 0, href: "#home" },
  { name: "ABOUT", id: 1, href: "#about" },
  { name: "SERVICES", id: 2, href: "#services" },
  { name: "CONTACT", id: 3, href: "#contact" },
];

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const genericHamburgerLine = `h-1 w-8 my-1 rounded-full bg-black transition ease transform duration-300 border-2 border-slate-700`;

  const handleClickScroll = (elem) => {
    const element = document.querySelector(elem.href);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Fragment>
      {/* DESKTOP */}
      <nav className="shadow-slate-300 shadow-sm bg-white hidden md:block w-full fixed z-10">
        <div className="container mx-auto flex justify-between items-center  py-4">
          <img src={logo} width={175} height={80} alt="logo" />
          <ul className="list-none flex uppercase gap-5 text-xl font-['DM_Sans'] leading-[35.8px]">
            {navLinks.map((elem) => (
              <li key={elem.id}>
                <button onClick={()=>handleClickScroll(elem)}
                  
                  className="hover:text-neonCyan transition-[0.25s] text-base"
                >
                  {elem.name}
                </button>
              </li>
            ))}
          </ul>
          <div className="flex gap-4">
            <img className="w-[18px]" src={pdf_icon} alt="requisition" />
            <a href={pdf} download="requisition" className="bg-neonCyan text-white text-base font-medium rounded-3xl px-5 py-2 hover:bg-black transition-[0.25s]">
               Download Requisition
            </a>
          </div>
        </div>
      </nav>

      {/* MOBILE */}
      <div className="flex justify-between w-screen items-center md:hidden py-2 px-6 fixed z-30 bg-white shadow-md">
       
          <img className="max-w-[160px]" src={logo} alt="" />
       
        <button
          className="flex flex-col h-12 w-12 justify-center items-center group"
          onClick={() => setIsOpen(!isOpen)}
        >
          <div
            className={`${genericHamburgerLine} ${
              isOpen ? "rotate-45 translate-y-3" : ""
            }`}
          />
          <div
            className={`${genericHamburgerLine} ${isOpen ? "opacity-0" : ""}`}
          />
          <div
            className={`${genericHamburgerLine} ${
              isOpen ? "-rotate-45 -translate-y-3" : ""
            }`}
          />
        </button>
      </div>
      <div
        className={`${
          isOpen
            ? "left-0 h-screen w-screen"
            : "left-[-1000px] h-screen w-screen"
        } transition-all duration-700 fixed bg-neonCyan z-20 lg:hidden`}
      >
        <div className="flex flex-col items-center justify-center h-full gap-y-16">
          <ul className="list-none uppercase gap-16 flex flex-col items-center justify-center">
            {navLinks.map((elem) => (
              <li key={elem.id}>
                <a
                  href={elem.href}
                  className="flex gap-2 text-white text-md font-medium"
                  onClick={() => setIsOpen(false)}
                >
                  {elem.name}
                  {elem.icon}
                </a>
              </li>
            ))}
          </ul>
          <div>
            <a href={pdf} download="requisition" className="bg-white px-5 py-3 text-neonCyan text-md font-semibold rounded-[50px]">
              Download requisition
            </a>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Navbar;
