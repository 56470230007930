import { useState } from "react";
import { CSSTransition } from "react-transition-group";
import "../../UI/Accordion.css";

function AccordionItem({ title, content, icon }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen((currentState) => !currentState);
  };

  return (
    <div className="bg-white rounded-md p-2 ps-4 lg:w-[473px] cursor-pointer">
      <div
        onClick={toggleAccordion}
        className="flex w-full items-center justify-between"
      >
        <span className="text-[18px] lg:text-[28px] text-black font-semibold">{title}</span>{" "}
        <div className="bg-[#006C7215] w-[44px] lg:w-[64px] h-[44px] lg:h-[64px] rounded-xl flex items-center justify-center">
          <img src={icon} className="lg:w-[40px] lg:h-[40px] w-[30px] h-[30px]" alt="" />
        </div>
      </div>
      <CSSTransition in={isOpen} timeout={300} classNames="slide" unmountOnExit>
        <p
          className={`text-[16px] lg:text-[16px] font-['DM_Sans'] text-[#817A79B5]  ${
            isOpen ? "opacity-100" : "opacity-0"
          } transition-all duration-1000`}
        >
          {content}
        </p>
      </CSSTransition>
    </div>
  );
}

export default AccordionItem;
