import { Fragment } from "react";
import Accordion from "../Accordion/Accordion";
import { accordionItems } from "../Tools/item-database";

const Facilities = () => {
  return (
    <Fragment>
      <div
        className="container mx-auto flex flex-col md:flex-row md:justify-center gap-12 py-12 scroll-mt-10"
        id="services"
      >
        <div className="flex flex-col items-center md:items-start text-center md:text-start gap-6 lg:gap-10">
          <span className="text-neonCyan text-[16px] bg-[#F4F6F7] p-2 w-[150px] rounded-md text-center font-bold">
            SERVICES
          </span>
          <h2 className="max-w-[260px] lg:max-w-[483px] md:text-[30px] lg:text-[40px]">
          Discover Our Range of Services
          </h2>
          <p className="max-w-[300px] md:max-w-[483px] lg:max-w-[583px] md:text-[20px]">
          From varicose veins treatments to vascular health consultations, we are here to deliver top-quality care tailored to your well-being. Discover how we can assist you in enhancing your vascular health and overall quality of life.
          </p>
          <div className="mt-4 lg:mt-0">
            <a
              href="#contact"
              className="px-6 py-3 bg-neonCyan text-white text-xl font-['DM_Sans'] rounded-3xl hover:bg-black transition-[0.25s]"
            >
              Call us
            </a>
          </div>
        </div>
        <div className="flex justify-center">
          <Accordion items={accordionItems} />
        </div>
      </div>
    </Fragment>
  );
};

export default Facilities;
