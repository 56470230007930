import { Fragment } from "react";
import about_img from "../../assets/about.jpg";

const About = () => {
  return (
    <Fragment>
      <div
        className="container mx-auto flex flex-col items-center md:flex-row md:justify-center py-12 gap-10 xl:gap-40 lg:gap-20 scroll-mt-10"
        id="about"
      >
        <img
          src={about_img}
          className="w-[340px] md:w-[400px] lg:w-[550px] rounded-[40px]"
          alt=""
        />
        <div className="flex flex-col justify-center items-center md:items-start gap-4  text-center md:text-start">
          <span className="text-neonCyan text-[16px] bg-[#F4F6F7] p-2 w-[115px] rounded-md text-center font-bold">
            ABOUT US
          </span>
          <h2 className="lg:max-w-[430px] lg:text-[40px] md:text-[30px]">Experts in Radiology Services</h2>
          <p className="max-w-[342px] lg:max-w-[448px] md:text-[16px]">
          Welcome to Prime Care Walk-In Clinic! We are a dedicated healthcare center, committed to providing exceptional medical services. Our compassionate and skilled team offers timely and accurate examinations for precise diagnoses.
          </p>
          <ul className="flex flex-col gap-4">
            <li className="about-list">
              <p className="text-black lg:text-[16px] md:text-[14px]">15+ Years Of Experience</p>
            </li>
            <li className="about-list">
              <p className="text-black lg:text-[16px] md:text-[14px]">Caring Staff</p>
            </li>
            <li className="about-list">
              <p className="text-black lg:text-[16px] md:text-[14px]">Fast Processing</p>
            </li>
            <li className="about-list">
              <p className="text-black lg:text-[16px] md:text-[14px]">Professional Experts</p>
            </li>
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

export default About;
