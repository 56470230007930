// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Define your slide-in and slide-out animations */
.slide-enter {
  overflow: hidden;
  max-height: 0;
}

.slide-enter-active {
  max-height: 500px; /* Adjust the maximum height as needed */
  transition: max-height 2s;
}

.slide-exit {
  max-height: 500px;
}

.slide-exit-active {
  max-height: 0;
  transition: max-height 0.6s cubic-bezier(0, 1, 0, 1);
}
`, "",{"version":3,"sources":["webpack://./src/UI/Accordion.css"],"names":[],"mappings":"AAAA,kDAAkD;AAClD;EACE,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,iBAAiB,EAAE,wCAAwC;EAC3D,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,oDAAoD;AACtD","sourcesContent":["/* Define your slide-in and slide-out animations */\r\n.slide-enter {\r\n  overflow: hidden;\r\n  max-height: 0;\r\n}\r\n\r\n.slide-enter-active {\r\n  max-height: 500px; /* Adjust the maximum height as needed */\r\n  transition: max-height 2s;\r\n}\r\n\r\n.slide-exit {\r\n  max-height: 500px;\r\n}\r\n\r\n.slide-exit-active {\r\n  max-height: 0;\r\n  transition: max-height 0.6s cubic-bezier(0, 1, 0, 1);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
