import icon_one from "../../assets/doctor.svg";
import icon_two from "../../assets/heart.svg";
import icon_three from "../../assets/x-ray.svg";
import icon_four from "../../assets/Frame 4.svg";
import icon_five from "../../assets/veins.svg";

export const cards = [
  {
    id: 0,
    title: "Happy Patients",
    number: "1500+",
    text: "Countless smiles and stories, one common theme: Happy Patients!",
  },
  {
    id: 1,
    title: "Images Taken",
    number: "50K+",
    text: "Counting the Way to Clarity - Thousands of Images Captured!",
  },
  {
    id: 2,
    title: "Years in Service",
    number: "10+",
    text: "Years of Expertise, Your Trusted Choice.",
  },
];

export const accordionItems = [
  {
    id: "f1",
    title: "Consultation",
    icon: icon_one,
    content:
      "Our expert radiology team offers compassionate care, clarity, and guidance to make your experience comfortable and informative.",
  },
  {
    id: "f2",
    title: "Family Doctor Services",
    icon: icon_three,
    content:
      "Prime Care Clinic provides comprehensive family doctor services for all ages. Our experienced physicians offer personalized care for routine check-ups, chronic condition management, and acute illnesses, ensuring your family's well-being at every stage of life.",
  },
  {
    id: "f3",
    title: "Vascular Surgeon Consultation",
    icon: icon_two,
    content:
      "Prime Care Clinic offers specialized vascular surgeon consultations for comprehensive vascular health care. Our experienced surgeons provide individualized evaluation and treatment options, prioritizing your well-being. Choose us for expert vascular care.",
  },
  // {
  //   id: "f4",
  //   title: "Ultrasound",
  //   icon: icon_four,
  //   content:
  //     "Accurate diagnostics and precision care with our advanced ultrasound service.",
  // },
  {
    id: "f5",
    title: "Vericose Veins Treatment",
    icon: icon_five,
    content:
      "Prime Care Clinic specializes in varicose veins treatment, utilizing advanced techniques for improved vascular health. Our personalized treatment plans offer effective, minimally invasive solutions to address varicose veins, enhancing both appearance and well-being. Choose us for expert care and renewed confidence.",
  },
];
